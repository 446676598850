import * as React from "react"
import Seo from "../components/seo"
import useBooks from "../hooks/useBooks"

const books = () => {
  const { books } = useBooks()

  return (
    <>
      <Seo title="Bækur" />
      <div id="baekur-page">
        <div className="heading-box">
          <h2 className="heading-primary">Bækur</h2>
        </div>

        <div className="books-post-container">{books()}</div>
      </div>
    </>
  )
}

export default books
